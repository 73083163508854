import React from 'react';

const AboutusMain = () => {
  return (
    <section className="mx-auto max-w-7xl px-8 2xl:max-w-screen-2xl py-4 bg-base text-center">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* Column 1 - Custom Background Color */}
        <div className="bg-[#50808C] py-[5rem] px-[8px] text-[#FFF] items-center rounded-lg shadow-md ">
        
          <p>
          Deep understanding of local business landscape and regulations
          </p>
        </div>

        {/* Column 2 */}
        <div className="bg-[#F0F4FA] py-[5rem] items-center rounded-lg shadow-md px-[8px]">
       
          <p className="">
          Expert team specializing in oil, electricity, water, and construction sectors
          </p>
        </div>

        {/* Column 3 */}
        <div className="bg-[#F0F4FA] py-[5rem] items-center rounded-lg shadow-md px-[8px]">
       
       <p className="">
       Comprehensive support for market entry, partnerships, and business sustainability.
       </p>
     </div>
      </div>
    </section>
  );
};

export default AboutusMain;
