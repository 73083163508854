import React from 'react';
import { Link } from 'react-router-dom';
import avatarOne from '../../images/aboutus.jpg';

const Aboutus = () => {
  return (
    <header
      className="relative w-[98%] mx-auto bg-cover bg-center 
      xs:h-auto sm:h-auto md:h-screen lg:h-screen"
      style={{
        borderRadius: '16px',
        boxSizing: 'border-box',
        backgroundImage: `url(${avatarOne})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="bg-black/50 absolute inset-0 rounded-[16px]" /> {/* Dark Overlay */}

      <div className="mx-auto max-w-7xl px-8 2xl:max-w-screen-2xl relative z-10 h-full flex justify-start py-[5rem]">
        <div className="flex flex-col justify-center items-start h-full text-start xs:w-full sm:px-2 sm:w-full md:w-[778px]">
          <p
            className="
              text-white font-semibold mb-4 
              text-[30px] leading-[30px]               /* Base for xsmall screens */
              sm:text-[24px] sm:leading-[28px]         /* Small screens */
              md:text-[46px] md:leading-[48px]         /* Medium screens */
              lg:text-[64px] lg:leading-[72px]         /* Large screens */
            "
          >
            About Company
          </p>
          <p className="text-textspan text-lg mb-4 xs:text-sm">
            Endless Building Company helps international companies enter and operate in Iraq's market successfully.
          </p>

          <Link to="/contact/*">
            <button className="bg-main text-white rounded-[8px] py-[12px] px-[16px] gap-[8px] text-sm md:py-3 md:px-6 sm:py-2 sm:px-4 hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white transition duration-300 ease-in-out">
              Contact Us
            </button>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Aboutus;
