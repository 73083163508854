import React from 'react';
import Header from '../Components/AboutUs/Aboutus'
import AboutusMain from '../Components/AboutUs/AboutusMain';
import Client from '../Components/Client/Client';

const HomePage = () => {
  return (
    <>
      <Header />
      <AboutusMain />
      <Client/>
    </>
  );
}

export default HomePage